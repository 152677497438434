<template>
  <!-- Topbar Start -->
  <div class="container-fluid topbar bg-secondary d-none d-xl-block w-100">
    <div class="container">
      <div class="row gx-0 align-items-center" style="height: 45px">
        <div class="col-lg-6 text-center text-lg-start mb-lg-0">
          <div class="d-flex flex-wrap">
            <a href="#" class="text-muted me-4"
              ><i class="fas fa-map-marker-alt text-success me-2"></i>Find A
              Location</a
            >
            <a href="tel:+250723822030" class="text-muted me-4"
              ><i class="fas fa-phone-alt text-success me-2"></i
              >+250723822030</a
            >
            <a href="mailto:info@kigalicarselfdrive.com" class="text-muted me-0"
              ><i class="fas fa-envelope text-success me-2"></i
              >info@kigalicarselfdrive.com</a
            >
          </div>
        </div>
        <div class="col-lg-6 text-center text-lg-end">
          <div class="d-flex align-items-center justify-content-end">
            <a href="#" class="btn btn-light btn-sm-square rounded-circle me-3"
              ><i class="fab fa-facebook-f"></i
            ></a>
            <a href="#" class="btn btn-light btn-sm-square rounded-circle me-3"
              ><i class="fab fa-twitter"></i
            ></a>
            <a href="#" class="btn btn-light btn-sm-square rounded-circle me-3"
              ><i class="fab fa-instagram"></i
            ></a>
            <a href="#" class="btn btn-light btn-sm-square rounded-circle me-0"
              ><i class="fab fa-linkedin-in"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Topbar End -->

  <!-- Navbar & Hero Start -->
  <div class="container-fluid nav-bar sticky-top px-0 px-lg-4 py-2 py-lg-0">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a href="" class="navbar-brand p-0">
          <h1 class="display-6 text">
            <img src="img/4.png" alt="Logo" />Kigali Car Self Drive
          </h1>
          <!-- <img src="img/1.png" alt="Logo"> -->
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav mx-auto py-0">
            <a href="/" class="nav-item nav-link active">Home</a>
            <a href="/about" class="nav-item nav-link">About</a>
            <a href="/cars" class="nav-item nav-link">Our Cars</a>
            <a href="/accomodation" class="nav-item nav-link">Accomodation</a>
            <a href="/service" class="nav-item nav-link">Service</a>

            <!-- <div class="nav-item dropdown">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                >Pages</a
              >
              <div class="dropdown-menu m-0">
                <a href="feature.html" class="dropdown-item">Our Feature</a>
                <a href="cars.html" class="dropdown-item">Our Cars</a>
                <a href="team.html" class="dropdown-item">Our Team</a>
                <a href="testimonial.html" class="dropdown-item">Testimonial</a>
                <a href="404.html" class="dropdown-item">404 Page</a>
              </div>
            </div> -->
            <a href="/contact" class="nav-item nav-link">Contact</a>
          </div>
          <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-primary rounded-pill py-2 px-4"
            >Book Now</a
          >
        </div>
      </nav>
    </div>
  </div>
  <!-- Navbar & Hero End -->

  <!-- Carousel Start -->
  <div class="carousel-header">
    <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
      <ol class="carousel-indicators">
        <li
          data-bs-target="#carouselId"
          data-bs-slide-to="0"
          class="active"
        ></li>
        <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
        <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
        <li data-bs-target="#carouselId" data-bs-slide-to="3"></li>
        <li data-bs-target="#carouselId" data-bs-slide-to="4"></li>
        <li data-bs-target="#carouselId" data-bs-slide-to="5"></li>
        <li data-bs-target="#carouselId" data-bs-slide-to="6"></li>
        <li data-bs-target="#carouselId" data-bs-slide-to="7"></li>
      </ol>
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
          <img
            src="img/carhire16.jpeg"
            class="img-fluid w-100"
            alt="First slide"
          />
          <div class="carousel-caption">
            <div class="container py-4">
              <div class="row g-5">
                <div
                  class="col-lg-6 fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay="1s"
                  style="animation-delay: 1s"
                ></div>
                <div
                  class="col-lg-6 d-none d-lg-flex fadeInRight animated"
                  data-animation="fadeInRight"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <div class="text-start">
                    <h6 class="display-5 text-white">
                      Get safari Car today and experience the magic with kigali
                      Rwanda Car Hire
                    </h6>
                    <p>Treat yourself everywhere in RWANDA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img
            src="img/WhatsApp-Image-2023-08-12-at-14.53.12-860x449.jpeg"
            class="img-fluid w-100"
            alt="First slide"
          />
          <div class="carousel-caption">
            <div class="container py-4">
              <div class="row g-5">
                <div
                  class="col-lg-6 fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <!-- <div class="bg-secondary rounded p-5">
                    <h4 class="text-white mb-4">CONTINUE CAR RESERVATION</h4>
                    <form>
                      <div class="row g-3">
                        <div class="col-12">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Select Your Car type</option>
                            <option value="1">VW Golf VII</option>
                            <option value="2">Audi A1 S-Line</option>
                            <option value="3">Toyota Camry</option>
                            <option value="4">BMW 320 ModernLine</option>
                          </select>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <a href="#" class="text-start text-white d-block mb-2"
                            >Need a different drop-off location?</a
                          >
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <button class="btn btn-light w-100 py-2">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div> -->
                </div>
                <div
                  class="col-lg-6 d-none d-lg-flex fadeInRight animated"
                  data-animation="fadeInRight"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <div class="text-start">
                    <h1 class="display-5 text-white">
                      Book your elephant car ride adventure today and experience
                      the wonder of Rwanda's wildlife!
                    </h1>
                    <p>Treat yourself everywhere in RWANDA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img
            src="img/carhire14.jpeg"
            class="img-fluid w-100"
            alt="First slide"
          />
          <div class="carousel-caption">
            <div class="container py-4">
              <div class="row g-5">
                <div
                  class="col-lg-6 fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <!-- <div class="bg-secondary rounded p-5">
                    <h4 class="text-white mb-4">CONTINUE CAR RESERVATION</h4>
                    <form>
                      <div class="row g-3">
                        <div class="col-12">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Select Your Car type</option>
                            <option value="1">VW Golf VII</option>
                            <option value="2">Audi A1 S-Line</option>
                            <option value="3">Toyota Camry</option>
                            <option value="4">BMW 320 ModernLine</option>
                          </select>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <a href="#" class="text-start text-white d-block mb-2"
                            >Need a different drop-off location?</a
                          >
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <button class="btn btn-light w-100 py-2">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div> -->
                </div>
                <div
                  class="col-lg-6 d-none d-lg-flex fadeInRight animated"
                  data-animation="fadeInRight"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <div class="text-start">
                    <h1 class="display-5 text-white">
                      RAV4 offers a comfortable and reliable driving experience.
                    </h1>
                    <p>Treat yourself everywhere in RWANDA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img
            src="img/carhire33.jpeg"
            class="img-fluid w-100"
            alt="First slide"
          />
          <div class="carousel-caption">
            <div class="container py-4">
              <div class="row g-5">
                <div
                  class="col-lg-6 fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <!-- <div class="bg-secondary rounded p-5">
                    <h4 class="text-white mb-4">CONTINUE CAR RESERVATION</h4>
                    <form>
                      <div class="row g-3">
                        <div class="col-12">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Select Your Car type</option>
                            <option value="1">VW Golf VII</option>
                            <option value="2">Audi A1 S-Line</option>
                            <option value="3">Toyota Camry</option>
                            <option value="4">BMW 320 ModernLine</option>
                          </select>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <a href="#" class="text-start text-white d-block mb-2"
                            >Need a different drop-off location?</a
                          >
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <button class="btn btn-light w-100 py-2">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div> -->
                </div>
                <div
                  class="col-lg-6 d-none d-lg-flex fadeInRight animated"
                  data-animation="fadeInRight"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <div class="text-start">
                    <h1 class="display-5 text-white">
                      The Toyota HiAce van is a dependable for both commercial
                      and personal use.
                    </h1>
                    <p>Treat yourself everywhere in RWANDA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img
            src="img/carhire10.jpeg"
            class="img-fluid w-100"
            alt="First slide"
          />
          <div class="carousel-caption">
            <div class="container py-4">
              <div class="row g-5">
                <div
                  class="col-lg-6 fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <!-- <div class="bg-secondary rounded p-5">
                    <h4 class="text-white mb-4">CONTINUE CAR RESERVATION</h4>
                    <form>
                      <div class="row g-3">
                        <div class="col-12">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Select Your Car type</option>
                            <option value="1">VW Golf VII</option>
                            <option value="2">Audi A1 S-Line</option>
                            <option value="3">Toyota Camry</option>
                            <option value="4">BMW 320 ModernLine</option>
                          </select>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <a href="#" class="text-start text-white d-block mb-2"
                            >Need a different drop-off location?</a
                          >
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <button class="btn btn-light w-100 py-2">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div> -->
                </div>
                <div
                  class="col-lg-6 d-none d-lg-flex fadeInRight animated"
                  data-animation="fadeInRight"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <div class="text-start">
                    <h1 class="display-5 text-white">
                      The Land Cruiser Prado is the perfect choice for
                      adventurous road trips
                    </h1>
                    <p>Treat yourself everywhere in RWANDA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img
            src="img/carhire29.jpeg"
            class="img-fluid w-100"
            alt="First slide"
          />
          <div class="carousel-caption">
            <div class="container py-4">
              <div class="row g-5">
                <div
                  class="col-lg-6 fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <!-- <div class="bg-secondary rounded p-5">
                    <h4 class="text-white mb-4">CONTINUE CAR RESERVATION</h4>
                    <form>
                      <div class="row g-3">
                        <div class="col-12">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Select Your Car type</option>
                            <option value="1">VW Golf VII</option>
                            <option value="2">Audi A1 S-Line</option>
                            <option value="3">Toyota Camry</option>
                            <option value="4">BMW 320 ModernLine</option>
                          </select>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <a href="#" class="text-start text-white d-block mb-2"
                            >Need a different drop-off location?</a
                          >
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <button class="btn btn-light w-100 py-2">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div> -->
                </div>
                <div
                  class="col-lg-6 d-none d-lg-flex fadeInRight animated"
                  data-animation="fadeInRight"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <div class="text-start">
                    <h1 class="display-5 text-white">
                      Get 15% off your rental! Choose Your Model
                    </h1>
                    <p>Treat yourself everywhere in RWANDA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img
            src="img/carhire37.jpeg"
            class="img-fluid w-100"
            alt="First slide"
          />
          <div class="carousel-caption">
            <div class="container py-4">
              <div class="row g-5">
                <div
                  class="col-lg-6 fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <!-- <div class="bg-secondary rounded p-5">
                    <h4 class="text-white mb-4">CONTINUE CAR RESERVATION</h4>
                    <form>
                      <div class="row g-3">
                        <div class="col-12">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Select Your Car type</option>
                            <option value="1">VW Golf VII</option>
                            <option value="2">Audi A1 S-Line</option>
                            <option value="3">Toyota Camry</option>
                            <option value="4">BMW 320 ModernLine</option>
                          </select>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <a href="#" class="text-start text-white d-block mb-2"
                            >Need a different drop-off location?</a
                          >
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <button class="btn btn-light w-100 py-2">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div> -->
                </div>
                <div
                  class="col-lg-6 d-none d-lg-flex fadeInRight animated"
                  data-animation="fadeInRight"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <div class="text-start">
                    <h1 class="display-5 text-white">
                      Get 15% off your rental! Choose Your Model
                    </h1>
                    <p>Treat yourself everywhere in RWANDA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <img
            src="img/gorilla1.jpg"
            class="img-fluid w-100"
            alt="First slide"
          />
          <div class="carousel-caption">
            <div class="container py-4">
              <div class="row g-5">
                <div
                  class="col-lg-6 fadeInLeft animated"
                  data-animation="fadeInLeft"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <!-- <div class="bg-secondary rounded p-5">
                    <h4 class="text-white mb-4">CONTINUE CAR RESERVATION</h4>
                    <form>
                      <div class="row g-3">
                        <div class="col-12">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Select Your Car type</option>
                            <option value="1">VW Golf VII</option>
                            <option value="2">Audi A1 S-Line</option>
                            <option value="3">Toyota Camry</option>
                            <option value="4">BMW 320 ModernLine</option>
                          </select>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <a href="#" class="text-start text-white d-block mb-2"
                            >Need a different drop-off location?</a
                          >
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-map-marker-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter a City or Airport"
                              aria-label="Enter a City or Airport"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Pick Up</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <div
                              class="d-flex align-items-center bg-light text-body rounded-start p-2"
                            >
                              <span class="fas fa-calendar-alt"></span
                              ><span class="ms-1">Drop off</span>
                            </div>
                            <input class="form-control" type="date" />
                            <select
                              class="form-select ms-3"
                              aria-label="Default select example"
                            >
                              <option selected>12:00AM</option>
                              <option value="1">1:00AM</option>
                              <option value="2">2:00AM</option>
                              <option value="3">3:00AM</option>
                              <option value="4">4:00AM</option>
                              <option value="5">5:00AM</option>
                              <option value="6">6:00AM</option>
                              <option value="7">7:00AM</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <button class="btn btn-light w-100 py-2">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div> -->
                </div>
                <div
                  class="col-lg-6 d-none d-lg-flex fadeInRight animated"
                  data-animation="fadeInRight"
                  data-delay="1s"
                  style="animation-delay: 1s"
                >
                  <div class="text-start">
                    <h1 class="display-5 text-white">
                      We'll transport you safely and efficiently to the starting
                      point of your gorilla trekking adventure
                    </h1>
                    <p>Treat yourself everywhere in RWANDA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselId"
        data-bs-slide="prev"
      >
        <span
          class="carousel-control-prev-icon bg-secondary wow fadeInLeft"
          data-wow-delay="0.2s"
          aria-hidden="false"
        ></span>
        <span class="visually-hidden-focusable">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselId"
        data-bs-slide="next"
      >
        <span
          class="carousel-control-next-icon bg-secondary wow fadeInRight"
          data-wow-delay="0.2s"
          aria-hidden="false"
        ></span>
        <span class="visually-hidden-focusable">Next</span>
      </button>
    </div>
  </div>
  <!-- Carousel End -->

  <!-- Car Steps Start -->
  <div class="container-fluid steps py-5">
    <div class="container py-5">
      <div
        class="text-center mx-auto pb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style="max-width: 800px"
      >
        <h1 class="display-5 text-capitalize text-white mb-3">
          Get Car Rent Process
        </h1>
        <h6 class="mb-0 text-white">
          Here is the step you can follow to get our car rent service, feel free
          to contact us please if you really want it +250723822030
        </h6>
      </div>
      <div class="row g-4">
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="steps-item p-4 mb-4">
            <h4>Come In Contact</h4>
            <p class="mb-0">
              Call or whatsaap text to this number ++250723822030 for more info
              or come to our office
            </p>
            <div class="setps-number">01.</div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="steps-item p-4 mb-4">
            <h4>Choose A Car</h4>
            <p class="mb-0">
              Choose one of the displayed car and let's deal with the price, it
              is affordable for everyone
            </p>
            <div class="setps-number">02.</div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="steps-item p-4 mb-4">
            <h4>Enjoy Driving</h4>
            <p class="mb-0">
              Last step, if you already paid the car you like , feel free to
              take car and self drive
            </p>
            <div class="setps-number">03.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Car Steps End -->

  <!-- Car Rent Start -->
  <div class="container-fluid blog py-5">
    <div class="container py-5">
      <div
        class="text-center mx-auto pb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style="max-width: 800px"
      >
        <h1 class="display-5 text-capitalize mb-3">Cars available we have</h1>
        <p class="mb-0">
          Reserve your perfect Rwandan adventure today! Our diverse fleet of
          rental cars is available for booking online. All cars are meticulously
          maintained for a reliable journey. Start planning your Rwandan escape
          and book your car rental now!
        </p>
      </div>
      <div class="row g-4">
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/carhire16.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Unforgettable Safaris, Rent a Safari car Land Cruiser Today"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>SAFARI CAR LANDCRUISER</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span>
                </div>
                <div class="small">
                  <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Manuel</span>
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Get safari Car today and experience the magic with kigali Rwanda
                Car Hire
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/carhire18.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>TOYOTA LANDCRUISER</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span>
                </div>
                <div class="small">
                  <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span>
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Book your Toyota Land Cruiser today and experience the freedom
                of the open road in Rwanda!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/carhire29.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Discover Rwanda in a RAV4 The Ultimate Adventure Vehicle"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>RAVA 4 2022</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span>
                </div>
                <div class="small">
                  <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span>
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                The 2022 Toyota RAV4 is your ideal companion for exploring all
                that Rwanda has to offer. Book yours today and start planning
                your unforgettable adventure!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-4">
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/carhire8.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Comfortable Tundra Rentals for Your Adventure"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>2011 Toyota Tundra</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span>
                </div>
                <div class="small">
                  <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span>
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                The 2011 Toyota Tundra is a versatile choice for those seeking a
                powerful and dependable truck. Book yours today and experience
                the confidence and capability of a Toyota truck!.
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/carhire20.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>TOYOTA LANDCRUIDER GXR</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span>
                </div>
                <div class="small">
                  <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span>
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Book your Toyota Land Cruiser GXR today and discover the
                pinnacle of off-road capability and luxurious comfort. Rwanda
                awaits!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="blog-item" title="Rent a RAV4 2018 for family vacations">
            <div class="blog-img">
              <img
                src="img/carhire12.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>RAVA 4 2018</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span>
                </div>
                <div class="small">
                  <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span>
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                The 2018 Toyota RAV4 offers a blend of practicality, fuel
                efficiency, and capability, making it the perfect choice for
                your Rwandan adventure. Book yours today and start planning your
                unforgettable journey!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-4">
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="blog-item">
            <div class="blog-img" title="Your Akagera adventure starts here">
              <img
                src="img/volcanoes.jpg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>Visit Zebra at akagera national parc</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <!-- <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span> -->
                </div>
                <div class="small">
                  <!-- <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span> -->
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Book your car ride today and create lasting memories on your
                Rwandan zebra safari!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/Rwanda-Wildlife-and-Gorilla-tour-12days.jpg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>Visit Girafe with our Car</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <!-- <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span> -->
                </div>
                <div class="small">
                  <!-- <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span> -->
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Choose a car ride package that suits your needs, from a quick
                trip to a nearby giraffe reserve to a full-day adventure
                designed to maximize giraffe sightings.
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/gorillasection.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>Visit Gorilla with our Car</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <!-- <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span> -->
                </div>
                <div class="small">
                  <!-- <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span> -->
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Book your car ride today and embark on a journey to witness the
                magic of mountain gorillas in Rwanda!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-4">
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/carhire9.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>TOYOTA LANDCRUISER TXL</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span>
                </div>
                <div class="small">
                  <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span>
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Book your Toyota Land Cruiser TXL today and embark on an
                unforgettable Rwandan adventure for the whole family or group!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/carhire42.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>TOYOTA RAVA 4</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span>
                </div>
                <div class="small">
                  <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span>
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Book your reliable and fuel-efficient RAV4 today and discover
                the magic of Rwanda!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/carhire33.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>TOYOTA HIACE VAN</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span>
                </div>
                <div class="small">
                  <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span>
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Book your reliable Toyota Hiace van today and embark on your
                unforgettable Rwandan adventure!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-4">
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/carhire41.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>TOYOTA LANDCRUISER TXL</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span>
                </div>
                <div class="small">
                  <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span>
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Book your Toyota Land Cruiser TXL today and embark on an
                unforgettable Rwandan adventure for the whole family or group!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/carhire40.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>TOYOTA 4X4 VIGO</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span>
                </div>
                <div class="small">
                  <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span>
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                The Toyota 4x4 Hilux Vigo is the ideal choice for exploring
                Rwanda's untamed beauty. Book yours today and embark on an
                unforgettable off-road adventure!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/carhire38.jpeg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>TOYOTA LANDCRUISER TXL</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span>
                </div>
                <div class="small">
                  <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span>
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Book your Toyota Land Cruiser TXL today and embark on an
                unforgettable Rwandan adventure for the whole family or group!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-4">
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/IMG-20230506-WA0085-1024x576.jpg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>AMAHORO STADIUM</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <!-- <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span> -->
                </div>
                <div class="small">
                  <!-- <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span> -->
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Book your car rental today and experience the excitement of
                Amahoro Stadium in comfort and style!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/51256880872_13b12a1ab5_h.jpg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>CONVENTION CENTRE KIGALI RWANDA</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <!-- <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span> -->
                </div>
                <div class="small">
                  <!-- <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span> -->
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Book your car rental today and experience the convenience and
                freedom of exploring Kigali during your convention center visit!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="img/FEdXttOXMAELidl.jpg"
                class="img-fluid rounded-top w-100"
                alt="Image"
              />
            </div>
            <div class="blog-content rounded-bottom p-4">
              <h4>Visit Elephat with our Car</h4>
              <div class="blog-comment my-3">
                <div class="small">
                  <!-- <span class="fa fa-user text-primary"></span
                  ><span class="ms-2">Kigali Car Self Drive</span> -->
                </div>
                <div class="small">
                  <!-- <span class="fa fa-road text-primary"></span
                  ><span class="ms-2">Automatic</span> -->
                </div>
              </div>
              <!-- <a href="#" class="h4 d-block mb-3"
                >Rental Cars how to check driving fines?</a
              > -->
              <p class="mb-3">
                Book your elephant car ride adventure today and experience the
                wonder of Rwanda's wildlife!
              </p>
              <a href="https://wa.me/+250723822030" target="_blank" class="btn btn-success"
                >Book Now <i class="fa fa-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Car Rent End -->
  <!-- Services Start -->
  <div class="container-fluid service py-5">
    <div class="container py-5">
      <div
        class="text-center mx-auto pb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style="max-width: 800px"
      >
        <h1 class="display-5 text-capitalize mb-3">
          Car Rental service we provide
        </h1>
        <p class="mb-0">
          Discover Kigali at your own pace with our reliable car rental
          service.Enjoy the freedom of exploring Rwanda's stunning landscapes
          and vibrant cities in the comfort of your own vehicle.
        </p>
      </div>
      <div class="row g-4">
        <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="service-item p-4">
            <div class="service-icon mb-4">
              <i class="fa fa-phone-alt fa-2x"></i>
            </div>
            <h5 class="mb-3">Car Rent Reservation by phone</h5>
            <p class="mb-0">
              Our friendly car rental specialists are available to answer your
              questions, help you choose the ideal vehicle for your needs, and
              secure your reservation over the phone.
            </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="service-item p-4">
            <div class="service-icon mb-4">
              <i class="fa fa-money-bill-alt fa-2x"></i>
            </div>
            <h5 class="mb-3">Special Rates</h5>
            <p class="mb-0">
              we offer exclusive discounts to fit your needs. Simply inquire
              about our Special Rates when booking your car rental and enjoy
              significant savings on your chosen vehicle.
            </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="service-item p-4">
            <div class="service-icon mb-4">
              <i class="fa fa-road fa-2x"></i>
            </div>
            <h5 class="mb-3">One Way Rental</h5>
            <p class="mb-0">
              Pick up your car at one location and drop it off at another,
              eliminating the need to backtrack and maximizing your exploration
              time.
            </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
          <div class="service-item p-4">
            <div class="service-icon mb-4">
              <i class="fa fa-umbrella fa-2x"></i>
            </div>
            <h5 class="mb-3">Tour Guide</h5>
            <p class="mb-0">
              Embark on an unforgettable Rwandan adventure with our passionate
              and knowledgeable tour guides! Immerse yourself in the rich
              history, vibrant culture, and breathtaking scenery with our
              personalized itineraries.
            </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
          <div class="service-item p-4">
            <div class="service-icon mb-4">
              <i class="fa fa-building fa-2x"></i>
            </div>
            <h5 class="mb-3">City to City</h5>
            <p class="mb-0">
              Whether you're planning a weekend getaway from Kigali to Butare or
              a multi-city exploration connecting national parks, we have the
              perfect vehicle to get you there comfortably and efficiently.
            </p>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
          <div class="service-item p-4">
            <div class="service-icon mb-4">
              <i class="fa fa-car-alt fa-2x"></i>
            </div>
            <h5 class="mb-3">Accomodations</h5>
            <p class="mb-0">
              We help Book effortlessly with our user-friendly platform, enjoy
              diverse options, and experience secure transactions with 24/7
              support. Let us handle the hassle - start your search now!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Services End -->

  <!-- Banner Start -->
  <div class="container-fluid banner pb-5 wow zoomInDown" data-wow-delay="0.1s">
    <div class="container pb-5">
      <div class="banner-item rounded">
        <img src="img/banner-1.jpg" class="img-fluid rounded w-100" alt="" />
        <div class="banner-content">
          <h2 class="text-primary">Rent Your Car</h2>
          <h1 class="text-white">Interested in Renting?</h1>
          <p class="text-white">Don't hesitate and send us a message.</p>
          <div class="banner-btn">
            <a
              href="https://wa.me/+250723822030" target="_blank"
              class="btn btn-success rounded-pill py-3 px-4 px-md-5 me-2"
              >WhatchApp</a
            >
            <a href="/contact" class="btn btn-info rounded-pill py-3 px-4 px-md-5 ms-2"
              >Contact Us</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Banner End -->

  <!-- Footer Start -->
  <div class="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="footer-item d-flex flex-column">
            <div class="footer-item">
              <h4 class="text-white mb-4">About Us</h4>
              <p class="mb-3">
                We are a leading car rental service provider in Kigali, Rwanda,
                passionate about providing exceptional service and a diverse
                fleet of vehicles to suit your every adventure.
              </p>
            </div>
            <div class="position-relative">
              <input
                class="form-control rounded-pill w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Enter your email"
              />
              <button
                type="button"
                class="btn btn-secondary rounded-pill position-absolute top-0 end-0 py-2 mt-2 me-2"
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="footer-item d-flex flex-column">
            <h4 class="text-white mb-4">Quick Links</h4>
            <a href="#"><i class="fas fa-angle-right me-2"></i> About</a>
            <a href="#"><i class="fas fa-angle-right me-2"></i> Cars</a>
            <a href="#"><i class="fas fa-angle-right me-2"></i> Car Types</a>
            <a href="#"><i class="fas fa-angle-right me-2"></i> Team</a>
            <a href="#"><i class="fas fa-angle-right me-2"></i> Contact us</a>
            <a href="#"
              ><i class="fas fa-angle-right me-2"></i> Terms & Conditions</a
            >
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="footer-item d-flex flex-column">
            <h4 class="text-white mb-4">Business Hours</h4>
            <div class="mb-3">
              <h6 class="text-muted mb-0">Mon - Friday:</h6>
              <p class="text-white mb-0">09.00 am to 07.00 pm</p>
            </div>
            <div class="mb-3">
              <h6 class="text-muted mb-0">Vacation:</h6>
              <p class="text-white mb-0">All Saturday is our Holy sabbath</p>
            </div>
            <div class="mb-3">
              <h6 class="text-muted mb-0">Sunday:</h6>
              <p class="text-white mb-0">10.00 am to 05.00 pm</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="footer-item d-flex flex-column">
            <h4 class="text-white mb-4">Contact Info</h4>
            <a href="#"
              ><i class="fa fa-map-marker-alt me-2"></i> RN 3 Street, Kigali,
              RWANDA</a
            >
            <a href="mailto:info@kigalicarselfdrive.com"
              ><i class="fas fa-envelope me-2"></i>
              info@kigalicarselfdrive.com</a
            >
            <a href="tel:+250723822030"
              ><i class="fas fa-phone me-2"></i> +250723822030</a
            >
            <a href="tel:+250723822030" class="mb-3"
              ><i class="fas fa-print me-2"></i> +250723822030</a
            >
            <div class="d-flex">
              <a
                class="btn btn-secondary btn-md-square rounded-circle me-3"
                href=""
                ><i class="fab fa-facebook-f text-white"></i
              ></a>
              <a
                class="btn btn-secondary btn-md-square rounded-circle me-3"
                href=""
                ><i class="fab fa-twitter text-white"></i
              ></a>
              <a
                class="btn btn-secondary btn-md-square rounded-circle me-3"
                href=""
                ><i class="fab fa-instagram text-white"></i
              ></a>
              <a
                class="btn btn-secondary btn-md-square rounded-circle me-0"
                href=""
                ><i class="fab fa-linkedin-in text-white"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer End -->

  <!-- Copyright Start -->
  <div class="container-fluid copyright py-4">
    <div class="container">
      <div class="row g-4 align-items-center">
        <div class="col-md-6 text-center text-md-start mb-md-0">
          <span class="text-body"
            ><a href="#" class="border-bottom text-white"
              ><i class="fas fa-copyright text-light me-2"></i>Kigali Car Self
              Drive</a
            >, All right reserved.</span
          >
        </div>
        <!-- <div class="col-md-6 text-center text-md-end text-body">
          Designed By
          <a class="border-bottom text-white" href=""
            >HTML Codex</a
          >
        </div> -->
      </div>
    </div>
  </div>
  <!-- Copyright End -->
  <!-- Back to Top -->
  <a href="#" class="btn btn-secondary btn-lg-square rounded-circle back-to-top"
    ><i class="fa fa-arrow-up"></i
  ></a>
</template>
